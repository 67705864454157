.blackC {
  color: black;
  margin-right: 10px;
}

.whiteC {
  color: white;
  margin-right: 10px;
}

.dropdown-item {
  background-color: grey !important;
}
.nav-bg {
  background-color: transparent;
  transition: all 0.2s ease;
}
.nav-bg:hover {
  background-color: #fff;
}

.nav-bg:hover .blackC {
  color: black;
}
.nav-bg2 {
  background-color: #fff;
  transition: all 0.5s ease;
}

input[type="text"] {
  background-color: #3cbc8d;
  color: white;
}

.customInput {
  border: 0px solid #ced4da;
  border-bottom: 1px solid #000;
  background-color: #f0f0f0;
  border-radius: 0px;
}

.customInput:focus {
  border: 0px solid #ced4da;
  border-bottom: 1px solid #000;
  background-color: #f0f0f0;
  border-radius: 0px;
  box-shadow: none;
}

.customInputButton {
  border-bottom: 1px solid #000;
  color: #000;
  padding: 6px;
}

/* test */
/* @import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700);
@import url(https://raw.github.com/FortAwesome/Font-Awesome/master/docs/assets/css/font-awesome.min.css); */

#wrap {
  display: inline-block;
  position: relative;
  height: 60px;
  float: right;
  padding: 0;
  position: relative;
}

input[type="text"] {
  height: 60px;
  font-size: 18px;
  display: inline-block;
  font-family: "Lato";
  font-weight: 100;
  border: none;
  outline: none;
  color: rgb(200, 164, 76);
  padding: 3px;
  padding-right: 60px;
  width: 0px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  z-index: 3;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
  cursor: pointer;
}

input[type="text"]:focus:hover {
  border-bottom: 1px solid rgb(255, 255, 255);
}

input[type="text"]:focus {
  width: 250px;
  z-index: 1;
  border-bottom: 1px solid rgb(255, 255, 255);
  cursor: text;
}
input[type="submit"] {
  margin-top: 15px;
  height: 20px;
  width: 20px;
  display: inline-block;
  color: rgb(200, 164, 76);
  float: right;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFU1NT9fX1lJSUXl5e1dXVfn5+c3Nz6urqv7+/tLS0iYmJqampn5+fysrK39/faWlp////Vi4ZywAAABF0Uk5T/////////////////////wAlrZliAAABLklEQVR42rSWWRbDIAhFHeOUtN3/ags1zaA4cHrKZ8JFRHwoXkwTvwGP1Qo0bYObAPwiLmbNAHBWFBZlD9j0JxflDViIObNHG/Do8PRHTJk0TezAhv7qloK0JJEBh+F8+U/hopIELOWfiZUCDOZD1RADOQKA75oq4cvVkcT+OdHnqqpQCITWAjnWVgGQUWz12lJuGwGoaWgBKzRVBcCypgUkOAoWgBX/L0CmxN40u6xwcIJ1cOzWYDffp3axsQOyvdkXiH9FKRFwPRHYZUaXMgPLeiW7QhbDRciyLXJaKheCuLbiVoqx1DVRyH26yb0hsuoOFEPsoz+BVE0MRlZNjGZcRQyHYkmMp2hBTIzdkzCTc/pLqOnBrk7/yZdAOq/q5NPBH1f7x7fGP4C3AAMAQrhzX9zhcGsAAAAASUVORK5CYII=)
    center center no-repeat;
  background-size: cover;
  text-indent: -10000px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.4s ease;
}

input[type="submit"]:hover {
  opacity: 0.8;
}
