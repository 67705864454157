.colDiv2 {
  overflow: hidden;
}

.hoverE2 {
  object-fit: cover;
  transition: all 0.3s ease;
}

.hoverE2:hover {
  transform: scale(1.2);
}
